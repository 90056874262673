import { FunctionComponent } from "react";

type Button = {
  label: string,
  link?: string
};

export const Button: FunctionComponent<Button> = ({ label, link }) => {
  return (
    link ? (
      <a href={ link } className="btn">{ label }</a>
    ) : (
      <button className="btn">{ label }</button>
    )
  );
};
