import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface isLoggedState {
  isLogged: boolean
}

const initialState: isLoggedState = {
  isLogged: false
};


export const isLoggedSlice = createSlice({
  name: 'isLogged',
  initialState,
  reducers: {
    isLogged: (state, action: PayloadAction<boolean>) => {
      state.isLogged = action.payload;
    },
  },
});

export const { isLogged } = isLoggedSlice.actions;

export default isLoggedSlice.reducer;
