import React, { FC, useEffect, useMemo, useState } from 'react';
import { Provider } from 'react-redux';
import { store } from '../store/store';
import { ImmutableMethodResults, ImmutableXClient, Link } from '@imtbl/imx-sdk';

type ProviderProps = {
  children?: React.ReactNode;
};

enum ProviderPreference {
  GAMESTOP = 'gamestop',
  METAMASK = 'metamask',
  MAGIC_LINK = 'magic_link',
  NONE = 'none',
}

export const Providers: FC<ProviderProps> = ({ children }) => {
  const link = new Link('https://link.sandbox.x.immutable.com');
  
  const [wallet, setWallet] = useState('');
  const [balance, setBalance] = useState<ImmutableMethodResults.ImmutableGetBalanceResult>(Object);
  const [client, setClient] = useState<ImmutableXClient>(Object);

  useEffect(() => {
    buildIMX()
  }, []);

  async function buildIMX() {
    const publicApiUrl: string = 'https://api.sandbox.x.immutable.com/v1';
    setClient(await ImmutableXClient.build({ publicApiUrl }));
  };

  async function linkSetup(): Promise<void> {
    const res = await link.setup({providerPreference: ProviderPreference.NONE});
    
    localStorage.setItem('WALLET_ADDRESS', res.address);

    setWallet(res.address);
  };

  async function disconnect(): Promise<void> {
    localStorage.removeItem('WALLET_ADDRESS');

    setWallet('');
  }
  
  return (
    <Provider store={ store }>
      {/* <button onClick={ linkSetup }>Setup</button>
      <div>Active wallet: { wallet }</div> */}
      { children }
      {/* <button onClick={ disconnect }>Disconnect</button> */}
    </Provider>
  );
}
