import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Home } from '../pages/home';
import { Landing } from '../pages/landing';
import { PrivacyPolicy } from '../pages/privacy-policy';
import { Success } from '../pages/success';
import { TermsConditions } from '../pages/terms-conditions';

export const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing /> }></Route>
        <Route path="/terms-and-conditions" element={<TermsConditions /> }></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy /> }></Route>
        <Route path="/success" element={<Success /> }></Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
