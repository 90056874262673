import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import I18NextXhrBackend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(I18NextXhrBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // debug: true,
    lng: 'en',
    fallbackLng: 'en',
    ns: ['interface'],
    defaultNS: 'interface',
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json`
    }
  });

export default i18n;
