import { useEffect, useState } from "react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { useContentful } from "../utils/contentful";
import { Generic } from "../components/layout/generic";
import { Button } from '../components/atoms/button';

import LOGO_PF from '../assets/pictures/logo_puttfolio.png';
import LOGO_KL from '../assets/pictures/logo_kingu_labs.png';
import LOGO_IMX from '../assets/pictures/logo_immutable.png';

import GOLF_BALL from '../assets/pictures/golf_ball.png';

type Homepage = {
  homepage: {
    structureCollection: {
      items: Array<Object>
    }
  }
}

export const Landing = () => {
  const [ homepage, setHomepage ] = useState<Homepage>();

  const query = `
  query homepageEntryQuery {
    homepage(id: "4A4bJgi2aLMmakiL4c8Ho8") {
      structureCollection {
        items {
          ...navigationFields
          ...introductionFields
          ...intersticialFields
          ...promiseFields
          ...footerFields
        }
      }
    }
  }

  fragment introductionFields on Introduction {
    __typename
    pageTitle {
      json
    }
    body {
      json
    }
    callToAction {
      label
      link
    }
    comingSoon
  }

  fragment intersticialFields on ImageInterstitial {
    __typename
    image {
      url
      description
    }
  }

  fragment promiseFields on Promise {
    __typename
    title
    description {
      json
    }
    callToAction {
      label
      link
    }
    image {
      url
    }
  }

  fragment footerFields on Footer {
    __typename
    body {
      json
    }
    navigation {
      navigationListCollection {
        items {
          ...navigationItemFields
          ...callToActionFields
        }
      }
    }
  }

  fragment navigationFields on Navigation {
    __typename
    navigationListCollection {
      items {
        ...navigationItemFields
        ...callToActionFields
      }
    }
  }

  fragment navigationItemFields on NavigationItem {
    __typename
    label
    link
    blank
  }

  fragment callToActionFields on CallToAction {
    __typename
    label
    link
  }
  `;

  let { data }: any = useContentful(query);

  const options = {
    renderText: (text: any) => {
      return text.split('\n').reduce((children: any, textSegment: any, index: any) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };

  useEffect(() => {
    setHomepage(data);
  }, [data]);

  return (
    <Generic pageClass="landing">
      <div className="landing__content">
        {
          homepage?.homepage.structureCollection.items.map((item: any) => {
            return (
              item.__typename === 'Navigation' ? (
                <header className="header">
                  <div className="container">
                    <div className="logo">
                      <img src={ LOGO_PF } alt="Puttfolio" />
                    </div>

                    <div className="header__navigation">
                      <ul>
                        {
                          item.navigationListCollection.items.map((navItem: any) => {
                            return (
                              navItem.__typename === 'NavigationItem' ? (
                                <li>
                                  {
                                    navItem.label === 'ig' ? (
                                      <a href={ navItem.link } className="header__navigation__item" target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={ faInstagram } size='lg' />
                                      </a>
                                    ) : navItem.label === 'tw' ? (
                                      <a href={ navItem.link } className="header__navigation__item" target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={ faTwitter } size='lg' />
                                      </a>
                                    ) : navItem.label === 'ds' ? (
                                      <a href={ navItem.link } className="header__navigation__item" target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={ faDiscord } size='lg' />
                                      </a>
                                    ) : (
                                      <></>
                                    )
                                  }
                                </li>
                              ) : navItem.__typename === 'CallToAction' ? (
                                <li>
                                  <Button label={ navItem.label } link={ navItem.link }></Button>
                                </li>
                              ) : (
                                <li></li>
                              )
                            )
                          })
                        }
                      </ul>
                    </div>
                  </div>
                </header>
              ) : item.__typename === 'ImageInterstitial' ? (
                <div className="intersticial">
                  <img src={ GOLF_BALL } alt="" className="golfball--back" />
                  <img src={ item.image.url } alt={ item.image.description } />
                  <img src={ GOLF_BALL } alt="" className="golfball--front" />
                </div>
              ) : item.__typename === 'Introduction' ? (
                <div className="introduction">
                  <div className="container">
                    { item.comingSoon && <div className="comingSoon">Coming soon</div> }
                    { documentToReactComponents(item.pageTitle.json, options) }
                    { documentToReactComponents(item.body.json, options) }
                    <Button label={ item.callToAction.label } link={ item.callToAction.link }></Button>
                  </div>
                </div>
              ) : item.__typename === 'Promise' ? (
                <div className="promise">
                  <div className="container">
                    <div className="promise__text">
                      <h2>{ item.title }</h2>
                      { documentToReactComponents(item.description.json, options) }
                      <Button label={ item.callToAction.label } link={ item.callToAction.link }></Button>
                    </div>
                    
                    <div className="promise__image">
                      <img src={ item.image.url } alt={ item.title } />
                    </div>
                  </div>
                </div>
              ) : item.__typename === 'Footer' ? (
                <footer className="footer">
                  <nav>
                    <ul>
                      {
                        item.navigation.navigationListCollection.items.map((navItem: any) => {
                          return (
                            navItem.__typename === 'NavigationItem' ? (
                              <li>
                                <a href={ navItem.link } target={ navItem.blank ? '_blank' : undefined } rel={ navItem.blank ? 'noreferrer' : undefined }>{ navItem.label }</a>
                              </li>
                            ) : navItem.__typename === 'CallToAction' ? (
                              <li>
                                <Button label={ navItem.label } link={ navItem.link }></Button>
                              </li>
                            ) : (
                              <li></li>
                            )
                          )
                        })
                      }
                    </ul>
                  </nav>
                  
                  <div className="footer__endline">
                    { documentToReactComponents(item.body.json, options) }

                    <div className="footer__endline__logos">
                      <img src={ LOGO_KL } alt="Kingu Labs" />
                      <img src={ LOGO_IMX } alt="Immutable X" />
                    </div>
                  </div>
                </footer>
              ) : (
                <div></div>
              )
            )
          })
        }
      </div>
    </Generic>
  );
};
