import { useEffect, useState } from "react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { useContentful } from "../utils/contentful";
import { Generic } from "../components/layout/generic";
import { Button } from '../components/atoms/button';

import LOGO_PF from '../assets/pictures/logo_puttfolio.png';
import LOGO_KL from '../assets/pictures/logo_kingu_labs.png';
import LOGO_IMX from '../assets/pictures/logo_immutable.png';
import { Link } from "react-router-dom";

type Privacy = {
  contentPage: {
    header: {
      navigationListCollection: {
        items: Array<Object>
      }
    }
    body: {
      json: any
    }
    footer: {
      body: {
        json: any
      }
      navigation: {
        navigationListCollection: {
          items: Array<Object>
        }
      }
    }
  }
}

export const PrivacyPolicy = () => {
  const [ privacy, setPrivacy ] = useState<Privacy>();

  const query = `
  query contentPageEntryQuery {
    contentPage(id: "Ki9zL6IE7nT3AOW6Ceijd") {
      header {
        ...navigationFields
      }
      body {
        json
      }
      footer {
        ...footerFields
      }
    }
  }

  fragment footerFields on Footer {
    __typename
    body {
      json
    }
    navigation {
      navigationListCollection {
        items {
          ...navigationItemFields
          ...callToActionFields
        }
      }
    }
  }

  fragment navigationFields on Navigation {
    __typename
    navigationListCollection {
      items {
        ...navigationItemFields
        ...callToActionFields
      }
    }
  }

  fragment navigationItemFields on NavigationItem {
    __typename
    label
    link
  }

  fragment callToActionFields on CallToAction {
    __typename
    label
    link
  }
  `;

  let { data }: any = useContentful(query);

  const options = {
    renderText: (text: any) => {
      return text.split('\n').reduce((children: any, textSegment: any, index: any) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };

  useEffect(() => {
    setPrivacy(data);
  }, [data]);

  return (
    <Generic pageClass="privacy">
      <div className="privacy__content">
        <header className="header">
          <div className="container">
            <Link className="logo" to={"/"}>
              <img src={ LOGO_PF } alt="Puttfolio" />
            </Link>

            <div className="header__navigation">
              <ul>
                {
                  privacy?.contentPage.header.navigationListCollection.items.map((navItem: any) => {
                    return (
                      navItem.__typename === 'NavigationItem' ? (
                        <li>
                          {
                            navItem.label === 'ig' ? (
                              <a href={ navItem.link } className="header__navigation__item" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={ faInstagram } size='lg' />
                              </a>
                            ) : navItem.label === 'tw' ? (
                              <a href={ navItem.link } className="header__navigation__item" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={ faTwitter } size='lg' />
                              </a>
                            ) : navItem.label === 'ds' ? (
                              <a href={ navItem.link } className="header__navigation__item" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={ faDiscord } size='lg' />
                              </a>
                            ) : (
                              <></>
                            )
                          }
                        </li>
                      ) : navItem.__typename === 'CallToAction' ? (
                        <li>
                          <Button label={ navItem.label } link={ navItem.link }></Button>
                        </li>
                      ) : (
                        <li></li>
                      )
                    )
                  })
                }
              </ul>
            </div>
          </div>
        </header>

        <article>
          <div className="container">
            <h1>Privacy policy</h1>
            { documentToReactComponents(privacy?.contentPage.body.json, options) }
          </div>
        </article>

        <footer className="footer">
          <nav>
            <ul>
              {
                privacy?.contentPage.footer.navigation.navigationListCollection.items.map((navItem: any) => {
                  return (
                    navItem.__typename === 'NavigationItem' ? (
                      <li>
                        <a href={ navItem.link }>{ navItem.label }</a>
                      </li>
                    ) : navItem.__typename === 'CallToAction' ? (
                      <li>
                        <Button label={ navItem.label } link={ navItem.link }></Button>
                      </li>
                    ) : (
                      <li></li>
                    )
                  )
                })
              }
            </ul>
          </nav>
          
          <div className="footer__endline">
            { documentToReactComponents(privacy?.contentPage.footer.body.json, options) }

            <div className="footer__endline__logos">
              <img src={ LOGO_KL } alt="Kingu Labs" />
              <img src={ LOGO_IMX } alt="Immutable X" />
            </div>
          </div>
        </footer>
      </div>
    </Generic>
  );
};
