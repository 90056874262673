import React from 'react';
import { createRoot } from 'react-dom/client';
import { Providers } from './utils/providers';
import AppRouter from './utils/approuter';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import reportWebVitals from './reportWebVitals';
import './utils/i18n';
import './assets/scss/app.scss';

Amplify.configure(awsExports);

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <Providers>
    <AppRouter />
  </Providers>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
