import React, { FunctionComponent } from "react";

type PageProps = {
  /** Layout of the page */
  pageClass?: string;
  children?: React.ReactNode;
};

export const Generic: FunctionComponent<PageProps> = ({ pageClass, children }) => {
  return (
    <>
      <div className={`layout__generic ${pageClass ? pageClass : ""}`}>
        { children }
      </div>
    </>
  );
};
